<template>
    <div>
        <portal to="destination">
            <a-button
                :disabled="
                    newSetup.floors.substring(0, 1) === '0' ||
                    newSetup.floors === ''
                "
                size="large"
                type="purple"
                class="back-button"
                :loading="loading"
                @click="next"
                >NEXT</a-button
            >
        </portal>
        <div style="margin-top: 10%">
            <a-form-model :model="newSetup" ref="newSetup" class="mt-3">
                <a-form-model-item
                    required
                    :rules="req('Please enter Number of Floors')"
                    prop="floors"
                >
                    <slot name="label">
                        <div class="flex" style="font-size: 28px">
                            <div style="display: inline-block">
                                The number of floors
                            </div>
                            <div
                                style="color: var(--red); display: inline-block"
                            >
                                *&nbsp;
                            </div>
                            <div style="display: inline-block">
                                (Digits Only)
                            </div>
                        </div>
                    </slot>
                    <a-input
                        placeholder="Enter a number"
                        v-model="newSetup.floors"
                        type="number"
                        :min="0"
                    ></a-input>
                </a-form-model-item>
            </a-form-model>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: Number,
    },
    data: () => ({
        newSetup: {
            floors: "",
        },
        loading: false,
    }),
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        country() {
            let { country = "" } = this.instance;
            if (!country) country = "";
            country = country.toLowerCase().trim();
            return country === "canada" || country === "ca" ? "CA" : "USA";
        },
    },
    methods: {
        skip() {
            return this.$emit("next");
        },
        next() {
            this.loading = true;

            this.$api
                .post(`/condos/${this.instance.id}`, {
                    name: `${this.instance.name}`,
                    floors: this.newSetup.floors,
                })
                .then(({ data }) => {
					this.$store.commit('UPDATE_CONDO', data);
                    this.$store.commit("ADD_FLOOR", data.floors);
                    this.$router.push("/showunits");
                    this.$api
                        .post(`/settings/:instance/condounits/user`, {
                            options: {
                                seenIntro: true,
                            },
                        })
                        .catch((err) => {
                            console.error(
                                "ERROR setting up intro properties for the user",
                                err
                            );
                        });
                })
                .catch((err) => {
                    console.error("ERROR in adding floors details", err);
                })
                .finally(() => {
                    this.loading = true;
                });
        },
        req(msg) {
            return { required: true, message: msg };
        },
    },
};
</script>

<style>
</style>
